import React from "react";
import { Link } from "gatsby";
import { Button, Box, Heading, Text } from "theme-ui";

const styles = {
  author: {
    display: `inline-block`,
    color: `alpha`,
  },
  occupation: {
    mb: 4,
  },
  specialty: {
    color: `text`,
    mb: 4,
  },
};
const HeroContent = () => (
  <>
    <Heading variant="h1">
      👋 Hi I'm <Text sx={styles.author}>Alex</Text>!
    </Heading>
    <Heading variant="h3" sx={styles.specialty}>
      I am a software developer and I write about the things I learn and work on. Welcome to
      my corner of the web.
    </Heading>
    <Box variant="buttons.group">
      <Button as={Link} to="/contact">
        Contact Me
      </Button>
      <Button variant="white" as={Link} to="/about">
        About Me
      </Button>
    </Box>
  </>
);

export default HeroContent;
