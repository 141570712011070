import React from "react";
import { Card, Text } from "theme-ui";
import Section from "@components/Section";
import Navigation from "@components/Navigation";
import useSiteMetadata from "@helpers-blog/useSiteMetadata";
import attachSocialIcons from "@helpers/attachSocialIcons";

const Social = () => {
  const { social } = useSiteMetadata();

  return (
    <Section aside title="Reach out">
      <Card variant="paper">
        <Text variant="p">
          Please feel free to contact me if you have any questions or are
          interested in collaborating.
        </Text>
        <Navigation items={attachSocialIcons(social)} iconOnly />
      </Card>
    </Section>
  );
};

export default Social;
